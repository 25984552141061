export * as userAgent from '@adeprez/capacitor-user-agent';
export * as core from '@capacitor/core';
export * as device from '@capacitor/device';
export * as filesystem from '@capacitor/filesystem';
export * as inAppBrowser from '@capacitor/inappbrowser';
export * as statusbar from '@capacitor/status-bar';
export * as fileOpener from '@capacitor-community/file-opener';
export * as safeArea from 'capacitor-plugin-safe-area';
export * as firebaseMessaging from '@capacitor-firebase/messaging';
export * as localNotifications from '@capacitor/local-notifications';
export * as nativeSettings from 'capacitor-native-settings';
