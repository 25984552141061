'use client';

import type { Environment } from '@olaisaac/event-dispatcher-sdk';
import React from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk';
import { useMutation } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import type { UserIdentification } from '@monorepo/interfaces';
import { CookiesKeys } from '@monorepo/interfaces';
import { useIsMobileFlag } from '@monorepo/unified-shell';
import type { EventContextData, SendEventInput } from './types';
import { ErrorHandler, getAppByPath, getScope, parseMixPanelProperties } from './utils';
export const EventContext = React.createContext({} as EventContextData);
const APP_ENV = process.env.FULL_ENV as Environment ?? 'not-defined';
const APP_VERSION = process.env.APP_VERSION ?? 'not-defined';
export const EventContextProvider = ({
  children,
  userIdentificationData
}: {
  children: React.ReactNode;
  userIdentificationData?: UserIdentification | null;
}) => {
  const isMobileApp = useIsMobileFlag();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const {
    eventDispatcherClient,
    isInitialized
  } = useEventDispatcher();
  const [isReady, setIsReady] = React.useState<boolean>(false);
  const [schoolIds, setSchoolIds] = React.useState<string[] | null>(null);
  React.useEffect(() => {
    if (isInitialized && !!userIdentificationData?.id) void eventDispatcherClient.identifyUser({
      userId: userIdentificationData.id
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, userIdentificationData?.id]);
  const isClassAppFlow = Cookies.get(CookiesKeys.GLOBAL_TOKEN);
  const getAppRuntimePlatform = React.useCallback((isClassAppFlow: string | undefined, appInstalled: boolean) => {
    if (isClassAppFlow) return 'classapp';
    if (appInstalled) return 'app';
    return 'web';
  }, []);

  // Retry fetching school_ids before setting state, with a fallback after max attempts.
  React.useEffect(() => {
    const MAX_ATTEMPTS = 10;
    const INTERVAL_MS = 500;
    let attempts = 0;
    const interval = setInterval(() => {
      const storedIds = Cookies.get(CookiesKeys.SCHOOL_IDS)?.split(',');
      if (storedIds && storedIds?.length > 0) {
        setSchoolIds(storedIds);
        clearInterval(interval);
      }
      attempts++;
      if (attempts >= MAX_ATTEMPTS) {
        setSchoolIds([]);
        clearInterval(interval);
      }
    }, INTERVAL_MS);
    return () => clearInterval(interval);
  }, []);
  React.useEffect(() => {
    if (isInitialized && schoolIds !== null) {
      void eventDispatcherClient.setGlobalProperties({
        application: 'rf-monorepo',
        environment: APP_ENV,
        realm: 'responsaveis',
        customProperties: parseMixPanelProperties({
          campaign_args: {
            utm_source: searchParams?.get('utm_source'),
            utm_medium: searchParams?.get('utm_medium'),
            utm_campaign: searchParams?.get('utm_campaign'),
            utm_content: searchParams?.get('utm_content')
          },
          app_version: APP_VERSION,
          person_id: userIdentificationData?.id,
          school_ids: schoolIds,
          app_runtime_platform: getAppRuntimePlatform(isClassAppFlow, isMobileApp)
        })
      }).then(() => setIsReady(true));
    }
  }, [isInitialized, eventDispatcherClient, userIdentificationData, searchParams, schoolIds, isMobileApp, isClassAppFlow, getAppRuntimePlatform]);
  React.useEffect(() => {
    if (isReady) {
      void eventDispatcherClient.trackPageView(parseMixPanelProperties({
        app: getAppByPath(pathname)
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isReady, searchParams?.toString()]);
  const {
    mutate
  } = useMutation({
    mutationFn: async (input: SendEventInput) => {
      if (!isReady) throw new ErrorHandler({
        code: 'INTERNAL_SERVER_ERROR',
        message: `Error sending event - EventDispatcher is not ready`
      });
      const {
        event,
        action,
        experiment,
        app,
        data,
        scope,
        entity,
        pageName,
        identifierName
      } = input;
      const response = await eventDispatcherClient.sendEvent({
        action: action ?? 'click',
        pageName,
        identifierName,
        name: event,
        scope: scope ?? getScope(app),
        entity,
        customProperties: parseMixPanelProperties({
          ...data,
          app,
          experiment
        })
      });
      if (!response.ok) throw new ErrorHandler({
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Error sending event'
      });
    },
    retry: 3,
    retryDelay: attempt => attempt * 1000
  });
  return <EventContext.Provider value={{
    isReady,
    mutate
  }} data-sentry-element="unknown" data-sentry-component="EventContextProvider" data-sentry-source-file="EventContextProvider.tsx">
      {children}
    </EventContext.Provider>;
};